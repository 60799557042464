import Pusher from "pusher-js"
import Echo from "laravel-echo"


window.Echo = new Echo({
    broadcaster: 'pusher',
    key: window.pusher_key,
    cluster: 'eu',
    encrypted: true,
});


(function(){
    window.onload = (event) => {

        Lang.setLocale(window.language);


        var url = window.location.href;
        var params = getParams(url)

        // if service call id is not present
        // do nothing
        if ('servicePointId' in params){


            var data = {
                servicePointId : params.servicePointId,
                token          : window.alias_token,
                locale         : window.language
            }

            // check if is valid for service call
            $.ajax({
                type: 'GET',
                crossDomain: true,
                dataType:'json',
                url : route('webapp.widget.service-call'),
                data,
                success(response){

                    if(response.status == 'ok'){
                        initChannel(response.channelId);
                        initServiceCallButton(data,response.html);
                    }else if(response.status == 'error'){
                        console.warn(status.message)
                    }
                },
                error(error){
                    alert('Error: check log')
                    console.log(error);
                }
            })
        };
    }
    /**
     * Get the URL parameters
     * source: https://css-tricks.com/snippets/javascript/get-url-variables/
     * @param  {String} url The URL
     * @return {Object}     The URL parameters
     */
    var getParams = function (url) {
        var params = {};
        var parser = document.createElement('a');
        parser.href = url;
        var query = parser.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            params[pair[0]] = decodeURIComponent(pair[1]);
        }
        return params;
    };

    function initChannel(channelId){
        // listen for service-call updates
        window.Echo.channel('service-calls.'+channelId)
            .listen('ServiceCallAccepted', e => {

            var serviceCall = e.serviceCall;

            // might not exist if user has refreshed the page
            var $serviceCallItem = null;
                
            if(serviceCall.type.code !== 'CUSTOM_MESSAGE'){
                $serviceCallItem = $('.service-call-item[data-id="'+serviceCall.type.id+'"]');
            }else{
                $serviceCallItem = $('.service-call-item[data-service-call-id="'+serviceCall.id+'"]');
            }


            if(!$serviceCallItem) return;

            // service call was 'ignored'
            if(serviceCall.acceptStatus == 'ignore'){
            $serviceCallItem.next().append($(`
                <div class="service-call-history-item">
                    ${__('companyadmin.canceled_by',{name:serviceCall.acceptedBy.name}) } (${currTime()})
                    <i class="red far fa-times-circle"></i>
                </div>
            `))
            }else{
            $serviceCallItem.next().append($(`
                <div class="service-call-history-item">
                    ${__('companyadmin.accepted_by',{name:serviceCall.acceptedBy.name}) } (${currTime()})
                    <i class="green far fa-check-circle"></i>
                </div>
            `))
            }

        });
    }

    function currTime(){
        var today = new Date();
        var time = today.getHours() + ":" + today.getMinutes();
        return time;
    }

    function initServiceCallButton(data, html){
        // Create service call button

        $('body').append(html);



        $('body').on('click touchend','.service-call-button, .service-call-menu-close',(e) => {
            e.preventDefault();
           $('.service-call-menu-container').toggleClass('hidden') 
        });

        // handle click/touch
        $('body').on('click','.service-call-menu-container .send-button',(e) => {

            var serviceCallTypeId = '';
            var message = '';
            var isCustomMessage = $(e.target).closest('button').hasClass('custom-message-button');
            if(isCustomMessage && $('#custom-message-input').val() == ''){
                console.error('empty input')
                return;
            }


            if(!isCustomMessage){
                var $serviceCall = $(e.target).closest('.service-call-item');
                $serviceCall.find('.service-call-item-send i').toggleClass('hidden');
                serviceCallTypeId = $serviceCall.data('id');
            }else{
                message = $('#custom-message-input').val();
                $('.custom-message-button.service-call-item-send i').toggleClass('hidden');
                serviceCallTypeId =  $(e.target).closest('button').data('id');
            }



            function showMessageStatus(typeId,status){
                // get button by type_id
                var $serviceCallItem = $('.service-call-item[data-id="'+typeId+'"]');

                // hide loader
                $serviceCallItem.find('.service-call-item-send i').toggleClass('hidden')

                // apend to history
                
                if(status == 'error'){
                    $serviceCallItem.next().append($(`
                        <div class="service-call-history-item gray">
                        ${__('companyadmin.not_sent')} (${currTime()})
                        <i class="far fa-times-circle"></i>
                        </div>
                    `));
                }else{
                    $serviceCallItem.next().append($(`
                        <div class="service-call-history-item gray">
                        ${__('companyadmin.sent')} (${currTime()})
                        <i class="far fa-check-circle"></i>
                        </div>
                    `));
                }


            }



            $.ajax({
                type: 'POST',
                crossDomain: true,
                dataType:'json',
                url : route('webapp.service-call',window.webapp),
                data: {...data, serviceCallTypeId, message},
                success(response){
                    // show sent status for predefiend actions
                    // sent or not
                    if(response.status === 'error' || response.serviceCall.type.code !== 'CUSTOM_MESSAGE'){
                        showMessageStatus(response.serviceCall.type.id,response.status);
                    }

                    // show custome message
                    if(response.status !== 'error' && response.serviceCall.type.code === 'CUSTOM_MESSAGE'){
                        $('.custom-message-button.service-call-item-send i').toggleClass('hidden');

                        $('.service-call-buttons-container').append($(`
                            <div class="service-call-item-container">
                                <div data-service-call-id="${response.serviceCall.id}" class="service-call-item is-custom">
                                    <div class="service-call-item-text">
                                        ${response.serviceCall.message}
                                    </div>
                                </div>
                                
                                <div class="service-call-history-container">
                                    <div class="service-call-history-item gray">
                                    ${__('companyadmin.sent')} (${currTime()})
                                    <i class="far fa-check-circle"></i>
                                    </div>
                                </div>
                            </div>
                        `));
                    }





                },  
                error(error){
                    console.log(error)
                    $('.service-call-container i').toggleClass('hidden');
                },
                complete(){
                    $('#custom-message-input').val('');
                }
            })
        });
    }
})();
